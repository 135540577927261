/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import VRGeoscience from 'views/VRGeoscience';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

import SEO from '../src/components/SEO';

export const Head = () => (
  <>
    <SEO title="VRGS by VRGeoscience Limited" />;
  </>
);

const IndexPage = () => {
  return <WithLayout component={VRGeoscience} layout={Main} />;
};

export default IndexPage;
